import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { MaskedInput } from 'propulsion-rc-v2';
import { Button } from '@shared_modules/local-propulsion/components';
import WhereToFindCodeTooltip from '../WhereToFindCodeTooltip';
import dynamicModuleRegistry from 'dynamic-module-registry';
import { getPageContent } from '../../utils';
import ResponsiveImage from './ResponsiveImage';
import './index.scss';

import spinner from '@shared_modules/rc-offer-spinner/spinner.svg';
import FundingCodeInvalidModal from '../FundingCodeInvalidModal';

import {
  DM_CODE_EXPIRED,
  DM_CODE_INVALID,
  DM_CODE_INVALID_EXPIRED_REDIRECT_URL,
  MAX_INVALID_DM_CODE_ATTEMPTS,
} from '../../constants/dmCode';
import ModalTextContent from '../FundingCodeInvalidModal/ModalTextContent';

const DMCodeInput = ({ onDMCodeSubmitHandler, submitStatus }) => {
  const experiments = dynamicModuleRegistry.get('optimizelyExperience') || {};
  const isDMPageRedirectVariant = experiments.PL_DM_Landing_Page_Redirect === 'A';
  const dmContent = getPageContent(experiments.PL_DM_LandingPage_Persado);
  const fundingCode = dynamicModuleRegistry.get('directMailFundingCodeBypass')
    ? new URL(window.location.href).searchParams.get('funding_code') ?? ''
    : '';
  const [DMCode, setDMCode] = useState({ code: fundingCode, codeFormatted: '' });
  const [showSpinner, setShowSpinner] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [invalidErrorCount, setInvalidErrorCount] = useState(1);

  useEffect(() => {
    let delaySubmitTimeout;
    if (isDMPageRedirectVariant && !window.history.state?.disableAutoSubmit && fundingCode) {
      // show loader
      setShowSpinner(true);
      delaySubmitTimeout = setTimeout(() => {
        window.history.replaceState({ disableAutoSubmit: true }, null);
        onDMCodeSubmitHandler(DMCode.code);
      }, 2000);
    }
    return () => {
      clearTimeout(delaySubmitTimeout);
    };
  }, []);

  const onDMCodeSubmit = event => {
    event.preventDefault();
    onDMCodeSubmitHandler(DMCode.code);
  };

  const onDMCodeChange = event => {
    // we want the raw value, not the masked value
    setDMCode({ code: event.target.rawValue, codeFormatted: event.target.value });
  };

  const hasError = submitStatus && submitStatus.error;
  const ctaRedirectInvalidExpiredErr = () => window.location.assign(DM_CODE_INVALID_EXPIRED_REDIRECT_URL);
  const errorHandlerByType = {
    [DM_CODE_INVALID]: {
      handler: () => {
        if (invalidErrorCount < MAX_INVALID_DM_CODE_ATTEMPTS) {
          setInvalidErrorCount(invalidErrorCount + 1);
        } else {
          setShowErrorModal(true);
          setInvalidErrorCount(1);
        }
      },
      callToAction: ctaRedirectInvalidExpiredErr,
      children: (
        <ModalTextContent
          classNameTexts="FundingCodeInvalidModal"
          texts={{
            title: 'Invalid RSVP code',
            subtitle: `The RSVP code [${DMCode.codeFormatted}] you entered is invalid.`,
            secondarySubtitle: 'Would you like to start a new application?',
            text: 'We would ask you a few questions to make the best offer for you in no time.',
          }}
        />
      ),
    },
    [DM_CODE_EXPIRED]: {
      handler: () => setShowErrorModal(true),
      callToAction: ctaRedirectInvalidExpiredErr,
      children: (
        <ModalTextContent
          classNameTexts="FundingCodeInvalidModal"
          texts={{
            title: 'Uh Oh.',
            subtitle: `Your RSVP code [${DMCode.codeFormatted}] has expired`,
            secondarySubtitle: 'No worries! You can still start a new application.',
            text: 'We will ask you a few questions to make the best offer for you in no time.',
          }}
        />
      ),
    },
  };

  useEffect(() => {
    const submitError = hasError && submitStatus.error.error;
    errorHandlerByType[submitError]?.handler();
    return () => {
      setInvalidErrorCount(1);
    };
  }, [submitStatus]);

  useEffect(() => {
    if (hasError) {
      setShowSpinner(false);
    }
  }, [hasError]);

  const errorMsg = hasError && (
    <div className="DMCodeInput-error u-colorCardinal500 u-microText">{submitStatus.error.message}</div>
  );
  const { children, callToAction } = errorHandlerByType[submitStatus?.error?.error] || {};
  return (
    <section className="DMCodeInput u-positionRelative">
      {showSpinner && (
        <div className="DMCodeInput-spinner">
          <img src={spinner} alt="We are processing your code..." />
        </div>
      )}
      <ResponsiveImage displayVariant={dmContent.isCoverImgVariant} />

      <div className={classnames('DMCodeInput-container', { 'DMCodeInput-offset': !hasError })}>
        <div className="DMCodeInput-content">
          <h1 className="DMCodeInput-title u-heroText">{dmContent.headline}</h1>
          <div className="DMCodeInput-desc u-bodyText">{dmContent.body}</div>
          <form className="DMCodeInput-form" onSubmit={onDMCodeSubmit}>
            <label
              htmlFor="DMCode"
              className={classnames('DMCodeInput-formTitle u-title2Text', {
                'DMCodeInput-formTitle--custom': dmContent.isLabelVariant,
              })}
            >
              {showSpinner ? 'Welcome! we are processing your code...' : 'Enter your RSVP code to check your rate:'}
            </label>
            <div className="DMCodeInput-formInput">
              <MaskedInput
                maskOptions={{
                  mask: '####-####-####',
                  definitions: {
                    // define valid char as an uppercase char or num
                    '#': /[A-Z0-9]/,
                  },
                  prepare: str => str.toUpperCase(),
                  commit: (valueFormat, { unmaskedValue }) =>
                    setDMCode({ code: unmaskedValue, codeFormatted: valueFormat }),
                }}
                name="DMCode"
                defaultValue={DMCode.code}
                placeholder="Enter your 12-digit code"
                fullWidth
                onChange={onDMCodeChange}
                className="DMCodeInput-formInput"
                intent={hasError ? 'error' : undefined}
                data-qa-id="dm-code-input"
                aria-label="Enter DMCode"
                disabled={!!fundingCode && !hasError}
                id="DMCode"
              />
              {errorMsg}
            </div>
            <WhereToFindCodeTooltip>
              <a className="DMCodeInput-ClickTextBelowInput u-textDecorationUnderline u-positionRelative u-fontTextMedium u-colorSapphire500">
                Where do I find my code?
              </a>
            </WhereToFindCodeTooltip>
            <div className="DMCodeInput-formBtn">
              <Button
                name="submit-button"
                type="submit"
                fullWidth
                className={classnames('DMCodeInput-cta', {
                  'DMCodeInput-cta--custom': dmContent.isCtaVariant,
                })}
                disabled={showSpinner}
              >
                {dmContent.ctaText}
              </Button>
            </div>
            <div className="u-microText u-fontTextBold u-colorDenim500">
              {dmContent.subhead}
              {dmContent.hasSup && <sup>1</sup>}
            </div>
          </form>
        </div>
      </div>
      <FundingCodeInvalidModal
        handleClose={() => setShowErrorModal(false)}
        showModal={showErrorModal}
        handleCTAClick={callToAction}
      >
        {children}
      </FundingCodeInvalidModal>
    </section>
  );
};

DMCodeInput.propTypes = {
  onDMCodeSubmitHandler: PropTypes.func.isRequired,
  submitStatus: PropTypes.shape({
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
  }),
};

export default DMCodeInput;

