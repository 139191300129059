import { prescreenType, TOPUP_SOURCES as TOPUP_SOURCES_FROM_PSS } from '../pss-entry';
import React from 'react';
import {
  areThereAnyFees,
  amIRequiredToTakeOutExtraAmount,
  howMuchCanIBorrow,
  whatHappensAfterICheck,
  whatIsTopUpLoan,
  howCanIUseMyTopUpLoan,
  willCheckingMyRateAffectMyScore,
  howQuicklyCanIGetTopUpLoan,
  whatCouldAffectTheFinalAmountOfCash,
  shouldIContinueMakingPayments,
  howDebtPayoffLoanWork,
  whatTypeOfDebtsQualify,
  doINeedToPayMyDebtInFull,
  whatAreTheRequirementsToGetDiscountedRate,
  willIKeepDiscountedRateForLifeOfTheLoan,
  whenWillLendingClubProcessThePayments,
  howWillITrackThePayments,
  willLendingClubCloseMyAccounts,
  whenWillIGetTheRemainingFunds,
} from '../../shared_modules/baui-ui/components/FAQ/questions';

export const TOPUP_SOURCES = TOPUP_SOURCES_FROM_PSS;

export const OFFERS = {
  INVESTOR_MESSAGE:
    'Loan offers are not guaranteed and are subject to investor demand. Once you submit a finalized application, we’ll look for an investor. If we cannot find a suitable investor, your request for a loan may be cancelled.',
};

export const TIL = {
  INVESTOR_MESSAGE:
    'Loan issuance is not guaranteed and is subject to investor demand. If we cannot find a suitable investor, your request for a loan may be canceled.',
};

export const REPEATS = {
  SPINNER: {
    default: {
      header: 'One moment',
      text: 'while we help build your loan...',
    },
    promise: {
      title: 'Just a moment...',
      subtitle: 'Processing your application',
      successTitle: 'Your offer is ready!',
    },
    inprogress: {
      title: 'Just a moment...',
      subtitle: 'Setting up CleanSweep™',
    },
    [prescreenType.TOP_UP]: {
      title: 'Just a moment...',
      subtitle: 'Processing your request',
    },
    simple: {
      title: 'Just a moment...',
    },
  },
  PROGRESS_NAV_BAR_TYPES: {
    ONT: 'ont',
    PRE_APPROVED: 'preApproved',
    ILOC: 'iloc',
  },
};

/**
 * @typedef {"EASY_APPLY" | "BT_MANDATORY" | "BTA" | "CASH"} OfferType
 */
/**
 * Offer Flows, include new one only if you are sure it is a new flow even if we reuse pages, we need to be sure we need different
 *
 *
 */
export const OFFER_TYPES = {
  EASY_APPLY: 'EASY_APPLY',
  BT_MANDATORY: 'BT_MANDATORY',
  BTA: 'BTA',
  CASH: 'CASH',
};

export const OFFER_MODALS = {
  BT: {
    PAY_DOWN_AMOUNT: 'PAY_DOWN_AMOUNT',
    BT_HELP: 'BT_HELP',
    BT_MANDATORY: 'BT_MANDATORY',
    SAVINGS_COMPARE_MODAL: 'SAVINGS_COMPARE_MODAL',
  },
  CASH: {
    PLAID: 'PLAID',
    OFFER_CONGRATS: 'OFFER_CONGRATS',
    PA_CONGRATS: 'PA_CONGRATS',
  },
  LOAN_CHOICE_DISCOUNTED_O_FEE: 'LOAN_CHOICE_DISCOUNTED_O_FEE',
  LOAN_CHOICE: 'LOAN_CHOICE',
  LOW_DISCOUNTED_O_FEE: 'DISCOUNTED_O_FEE',
  ZERO_O_FEE: 'ZERO_O_FEE',
  CREDIT_UNION: 'CREDIT_UNION',
};

export const OFFER_TOOLTIPS = {
  LOW_DISCOUNTED_O_FEE: 'DISCOUNTED_O_FEE',
};

export const OFFER_TEMPLATES = {
  BT: {
    BTA: 'offers-bta-toggle/template',
  },
  EASY_APPLY: 'offers-bt-easy-apply/template',
  CASH: 'offers-cash-redesign/server/template',
};

export const BT_OFFER_UP_KEYS = {
  OFFER_UP: 'BT_OfferUp',
  OFFER_UP1: 'BT_OfferUp1',
};

export const CASH_OFFER_UP_KEYS = {
  OFFER_UP: 'Cash_OfferUp',
  OFFER_UP1: 'Cash_OfferUp1',
};

export const OFFER_UP_TAGS = {
  BT: [BT_OFFER_UP_KEYS.OFFER_UP, BT_OFFER_UP_KEYS.OFFER_UP1],
  CASH: [CASH_OFFER_UP_KEYS.OFFER_UP, CASH_OFFER_UP_KEYS.OFFER_UP1],
};

export const DISCOUNT_STATUS = {
  QUALIFIED: 'QUALIFIED',
  DISCOUNTED: 'DISCOUNTED',
};

export const DISCOUNT_TYPES = {
  ONT: 'ont',
  PRE_APPROVED: 'preapproved',
};

export const ONT_STATUS = { QUALIFIED: 'QUALIFIED', DISCOUNTED: 'DISCOUNTED' };

export const ONT_KEY = {
  OFEE: {
    DISC: 'ONT_OFEE_DISCOUNTED',
    QUAL: 'ONT_OFEE_QUALIFIED',
    text: 'ofee',
    preFix: 'ofee_',
  },
  COUPON: {
    DISC: 'ONT_COUPON_DISCOUNTED',
    QUAL: 'ONT_COUPON_QUALIFIED',
    text: 'coupon',
  },
};

export const PA_KEY = {
  PRE_APPROVED: {
    QUAL: 'PRE_APPROVED',
    text: 'non-discount',
  },
  COUPON: {
    QUAL: 'PRE_APPROVED_COUPON_QUALIFIED',
    DISC: 'PRE_APPROVED_COUPON_DISCOUNTED',
    preFix: 'coupon_',
  },
  OFEE: {
    QUAL: 'PRE_APPROVED_OFEE_QUALIFIED',
    DISC: 'PRE_APPROVED_OFEE_DISCOUNTED',
  },
};

const ONT_OFEE_DISCOUNTED = ONT_KEY.OFEE.DISC;
const ONT_OFEE_QUALIFIED = ONT_KEY.OFEE.QUAL;
const ONT_COUPON_QUALIFIED = ONT_KEY.COUPON.QUAL;
const ONT_COUPON_DISCOUNTED = ONT_KEY.COUPON.DISC;

// ONT DISCOUNTED KEYS
export const ONT_OFEE_DISC_PAYLOAD_KEY_1 = `${ONT_OFEE_DISCOUNTED}|1`;
export const ONT_OFEE_DISC_PAYLOAD_KEY_2 = `${ONT_OFEE_DISCOUNTED}|2`;
export const ONT_COUPON_DISC_PAYLOAD_KEY_1 = `${ONT_KEY.COUPON.DISC}|1`;

// ONT QUALIFIED KEYS
export const ONT_OFEE_QUAL_PAYLOAD_KEY_1 = `${ONT_OFEE_QUALIFIED}|1`;
export const ONT_OFEE_QUAL_PAYLOAD_KEY_2 = `${ONT_OFEE_QUALIFIED}|2`;
export const ONT_COUPON_QUAL_PAYLOAD_KEY_1 = `${ONT_KEY.COUPON.QUAL}|1`;

export const OFEE = ONT_KEY.OFEE.text;
export const COUPON = ONT_KEY.COUPON.text;
export const PA_V1_NON_DISCOUNT = PA_KEY.PRE_APPROVED.text;

export const PA_OFEE_QUALIFIED_V1 = `${PA_KEY.OFEE.QUAL}|1`;
export const PA_OFEE_DISCOUNTED_V1 = `${PA_KEY.OFEE.DISC}|1`;
export const PA_COUPON_DISCOUNTED_V1 = `${PA_KEY.COUPON.DISC}|1`;
export const PA_COUPON_QUALIFIED_V1 = `${PA_KEY.COUPON.QUAL}|1`;

// ** PSS context keys **
export const PA_COUPON_QUALIFIED = 'COUPON_QUALIFIED';
export const PA_OFEE_QUALIFIED = 'PA_OFEE_QUALIFIED';
export const PA_OFEE_DISCOUNTED = 'PA_OFEE_DISCOUNTED';

export const PA_QUAL_LIST = [PA_COUPON_QUALIFIED, PA_OFEE_QUALIFIED];

export const PA_V1_QUALIFIED = `${PA_KEY.PRE_APPROVED.QUAL}|1`; // to identify pre approve v1 type (non-dicount)
// to handle non mapped payload types e.g. when there's no payloadMap from offer item
export const PSS_CONTEXT_PAYLOAD_TYPES_MAP = {
  [PA_COUPON_QUALIFIED]: DISCOUNT_TYPES.PRE_APPROVED,
  [PA_OFEE_QUALIFIED]: DISCOUNT_TYPES.PRE_APPROVED,
};
// **

//this can be changed based on PBS changes
export const ONT_TYPES = {
  [ONT_OFEE_QUALIFIED]: OFEE,
  [ONT_OFEE_DISCOUNTED]: OFEE,
  [ONT_COUPON_QUALIFIED]: COUPON,
  [ONT_COUPON_DISCOUNTED]: COUPON,
  [ONT_OFEE_QUAL_PAYLOAD_KEY_1]: OFEE,
  [ONT_OFEE_DISC_PAYLOAD_KEY_1]: OFEE,
  [ONT_OFEE_QUAL_PAYLOAD_KEY_2]: OFEE,
  [ONT_OFEE_DISC_PAYLOAD_KEY_2]: OFEE,
  [ONT_COUPON_QUAL_PAYLOAD_KEY_1]: COUPON,
  [ONT_COUPON_DISC_PAYLOAD_KEY_1]: COUPON,
};

export const KEY_PREFIX = {
  [ONT_OFEE_QUAL_PAYLOAD_KEY_1]: ONT_KEY.OFEE.preFix,
  [ONT_OFEE_DISC_PAYLOAD_KEY_1]: ONT_KEY.OFEE.preFix,
  [PA_COUPON_QUALIFIED]: PA_KEY.COUPON.preFix,
  [PA_OFEE_DISCOUNTED_V1]: PA_KEY.OFEE.preFix,
};

export const DISC_PCT = 'disc_pct';
export const DISC_LEVEL = 'disc_level';

export const DISC_PCT_KEYS = {
  [ONT_OFEE_QUAL_PAYLOAD_KEY_1]: 'disc_est_pct',
  [ONT_OFEE_DISC_PAYLOAD_KEY_1]: DISC_PCT,
  [ONT_OFEE_QUAL_PAYLOAD_KEY_2]: DISC_PCT,
  [ONT_OFEE_DISC_PAYLOAD_KEY_2]: DISC_PCT,
  [ONT_COUPON_QUAL_PAYLOAD_KEY_1]: DISC_LEVEL,
  [ONT_COUPON_DISC_PAYLOAD_KEY_1]: DISC_LEVEL,
};

export const PL_TOPUP_OFFER_DETAILS_V1 = 'PL_TOPUP_OFFER_DETAILS|1';
export const PL_TOPUP_OFFER_V1 = 'PL_TOPUP_OFFER|1';

export const PRE_APPROVED_STATUS = {
  [PA_COUPON_QUALIFIED_V1]: DISCOUNT_STATUS.QUALIFIED,
  [PA_COUPON_DISCOUNTED_V1]: DISCOUNT_STATUS.DISCOUNTED,
  [PA_COUPON_QUALIFIED]: DISCOUNT_TYPES.PRE_APPROVED,
};

export const PAYLOAD_MAP_TYPES = {
  [ONT_OFEE_QUALIFIED]: DISCOUNT_TYPES.ONT,
  [ONT_OFEE_DISCOUNTED]: DISCOUNT_TYPES.ONT,
  [ONT_COUPON_QUALIFIED]: DISCOUNT_TYPES.ONT,
  [ONT_COUPON_DISCOUNTED]: DISCOUNT_TYPES.ONT,
  [ONT_OFEE_QUAL_PAYLOAD_KEY_1]: DISCOUNT_TYPES.ONT,
  [ONT_OFEE_DISC_PAYLOAD_KEY_1]: DISCOUNT_TYPES.ONT,
  [ONT_OFEE_QUAL_PAYLOAD_KEY_2]: DISCOUNT_TYPES.ONT,
  [ONT_OFEE_DISC_PAYLOAD_KEY_2]: DISCOUNT_TYPES.ONT,
  [ONT_COUPON_QUAL_PAYLOAD_KEY_1]: DISCOUNT_TYPES.ONT,
  [ONT_COUPON_DISC_PAYLOAD_KEY_1]: DISCOUNT_TYPES.ONT,
  [PA_COUPON_DISCOUNTED_V1]: DISCOUNT_TYPES.PRE_APPROVED,
  [PA_OFEE_DISCOUNTED_V1]: DISCOUNT_TYPES.PRE_APPROVED,
  [PA_V1_QUALIFIED]: DISCOUNT_TYPES.PRE_APPROVED,
  ...PSS_CONTEXT_PAYLOAD_TYPES_MAP,
};

export const PAYLOAD_MAP_SUBTYPES = {
  ...ONT_TYPES,
  [PA_COUPON_DISCOUNTED_V1]: COUPON,
  [PA_COUPON_QUALIFIED_V1]: COUPON, // PA Coupon Qualified type from offer item payloadMap
  [PA_COUPON_QUALIFIED]: COUPON, // PA Coupon Qualified type from PSS offers payloadType => "COUPON_QUALIFIED"
  [PA_V1_QUALIFIED]: PA_V1_NON_DISCOUNT,
  [PA_OFEE_QUALIFIED_V1]: OFEE,
  [PA_OFEE_DISCOUNTED_V1]: OFEE,
};

export const PAYLOAD_MAP_SUB_TYPES = {
  ...ONT_TYPES,
  [PA_COUPON_DISCOUNTED_V1]: COUPON,
};

export const PRODUCT_NAMES = {
  PAGAYA: 'Pagaya',
};

export const PRODUCT_OFFER_IDS = {
  1031: 'CU',
};

export const NON_CU_INVESTOR_IDS = {
  225173839: 'HFI',
};

export const SPECIAL_LIMITED_CHARACTERS_REGEX = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?]+/;
export const ALPHA_CHARACTERS_REGEX = /^[A-Za-z]+$/;

const DISCOUNT_BULLET_LABELS = {
  BT: 'Balance Transfer Discount',
  INTEREST_RATE: 'Interest Rate Discount',
  OFEE: 'Origination Fee Discount',
  DEBT_PAYOFF: 'Debt Paydown Discount',
};
export const discountedTooltipInfo = {
  control: {
    title: 'Discounted Rate',
    content: 'The green numbers show savings with a Balance Transfer Loan vs a Cash Loan equivalent',
  },
  btRedesign: {
    title: 'Discounted Rate',
    content:
      'The debt paydown loan gives you a discounted rate. In this {{duration}}-month offer, you get a {{apr}} APR on the debt paydown loan compared to a {{aprCash}} APR if you chose the cash loan.',
  },
  singleDiscount: {
    title: 'Discounts Applied',
    content: 'The green numbers show your final rates with all discounts applied',
    discountList: [DISCOUNT_BULLET_LABELS.INTEREST_RATE],
  },
  multipleDiscount: {
    title: 'Multiple Discounts Applied',
    content: 'The green numbers show your final rates with all discounts applied',
    discountList: [DISCOUNT_BULLET_LABELS.BT, DISCOUNT_BULLET_LABELS.INTEREST_RATE],
  },
  OFeeMultiDiscount: {
    title: 'Discounts Applied',
    content: 'The green numbers show your final rates with all discounts applied',
    discountList: [DISCOUNT_BULLET_LABELS.BT, DISCOUNT_BULLET_LABELS.OFEE],
  },
  OFeeSingleDiscount: {
    title: 'Discounts Applied',
    content: 'The green numbers show your final rates with all discounts applied',
    discountList: [DISCOUNT_BULLET_LABELS.OFEE],
  },
};

export const CU_DISCLAIMER = {
  title: {
    text: 'We work with multiple investors to secure competitive rates for our members.',
  },
  subtitle: {
    text: 'A credit union may be selected to invest in your loan.',
  },
};

export const BETTER_RATE_DIFFS = {
  MIN: 0.0005,
  MAX: 0.0025,
};

export const FAQ_CONTENT = {
  TOPUP: {
    OFFER: [
      { ...areThereAnyFees },
      { ...amIRequiredToTakeOutExtraAmount },
      { ...howMuchCanIBorrow },
      { ...whatCouldAffectTheFinalAmountOfCash },
      { ...whatHappensAfterICheck },
      { ...shouldIContinueMakingPayments },
    ],
    LANDING: [
      { ...whatIsTopUpLoan },
      { ...howCanIUseMyTopUpLoan },
      { ...areThereAnyFees },
      { ...amIRequiredToTakeOutExtraAmount },
      { ...howMuchCanIBorrow },
      { ...willCheckingMyRateAffectMyScore },
      { ...howQuicklyCanIGetTopUpLoan },
      { ...whatHappensAfterICheck },
      { ...whatCouldAffectTheFinalAmountOfCash },
    ],
  },
  BT_REDESIGN: {
    BT_INTENT: [
      { ...howDebtPayoffLoanWork },
      { ...whatTypeOfDebtsQualify },
      { ...doINeedToPayMyDebtInFull },
      { ...whatAreTheRequirementsToGetDiscountedRate },
      { ...willIKeepDiscountedRateForLifeOfTheLoan },
      { ...whenWillLendingClubProcessThePayments },
      { ...howWillITrackThePayments },
      { ...willLendingClubCloseMyAccounts },
      { ...whenWillIGetTheRemainingFunds },
    ],
    BT_INTENT_DISPARITY: [
      { ...howDebtPayoffLoanWork },
      { ...whatTypeOfDebtsQualify },
      { ...doINeedToPayMyDebtInFull },
      { ...whenWillLendingClubProcessThePayments },
      { ...howWillITrackThePayments },
      { ...willLendingClubCloseMyAccounts },
      { ...whenWillIGetTheRemainingFunds },
    ],
    SUMMARY_PAGE: [
      { ...whenWillLendingClubProcessThePayments },
      { ...howWillITrackThePayments },
      { ...whenWillIGetTheRemainingFunds },
      { ...whatTypeOfDebtsQualify },
      { ...doINeedToPayMyDebtInFull },
      { ...willLendingClubCloseMyAccounts },
      { ...howDebtPayoffLoanWork },
      { ...whatAreTheRequirementsToGetDiscountedRate },
      { ...willIKeepDiscountedRateForLifeOfTheLoan },
    ],
    BT_WORKSHEET: [
      { ...whenWillLendingClubProcessThePayments },
      { ...howWillITrackThePayments },
      { ...whenWillIGetTheRemainingFunds },
      { ...whatTypeOfDebtsQualify },
      { ...doINeedToPayMyDebtInFull },
      { ...willLendingClubCloseMyAccounts },
      { ...howDebtPayoffLoanWork },
      { ...whatAreTheRequirementsToGetDiscountedRate },
      { ...willIKeepDiscountedRateForLifeOfTheLoan },
    ],
  },
};

export const DISCLAIMERS = {
  ILOC: 'iloc',
  LBB: 'lbb',
  TOPUP: {
    LANDING: 'TopUpLanding',
    EXAMPLE: 'TopUpExample',
    OFFER: 'TopUpOffer',
  },
  DEFAULT: {
    OFFER: 'defaultOffer',
  },
};

export const LANDING_PAGE_DISCLOSURE = {
  key: 'disclosure5',
  text: 'For TopUp Personal Loans, APR ranges from 10.49% to 35.99% and origination fee ranges from 5.00% to 8.00% of the additional cash out amount. APRs and origination fees are determined at the time of the application.  Lowest APR is available to borrowers with excellent credit.  Advertised rates and fees are valid as of March 21, 2024 and are subject to change without notice.',
};

export const OFFER_PAGE_EXTRA_DISCLOSURE = {
  key: 'disclosure1',
  text: '* A hard credit inquiry, which may affect your credit score, will only appear on your credit report if and when a loan is issued to you.',
};

export const OFFER_PAGE_DISCLOSURE = {
  key: 'disclosure2',
  text: 'All offers and terms are subject to application approval and completion of the loan issuance process. You may cancel your application at any time prior to issuance at absolutely no cost to you. There is no down payment, and you may repay your loan at any time with no additional costs or pre-payment penalties. An approved loan may issue for less than the full requested amount, and loan closing is contingent on your acceptance of all applicable agreements and disclosures.',
};

export const TOPUP_DISCLOSURE_1 = {
  key: 'topUp-disclosure1',
  text: '*Checking a rate through us generates a soft credit inquiry on a person’s credit report, which is visible only to that person. A hard credit inquiry, which is visible to that person and others, and which may affect that person’s credit score, only appears on the person’s credit report if and when a loan is issued to the person.',
};

export const TOPUP_DISCLOSURE_2 = {
  key: 'topUp-disclosure2',
  text: 'Credit eligibility is not guaranteed. APR and other credit terms depend upon credit score and other key financing characteristics, including but not limited to the amount financed, loan term length, and credit usage and history.',
};

export const TOPUP_DISCLOSURE_4 = {
  key: 'topUp-disclosure4',
  text: (
    <>
      <sup>1</sup>Between October 1, 2023 to December 31, 2023, Personal Loans issued by LendingClub Bank were funded
      within 65 hours after loan approval, on average 24% of Personal Loans issued by LendingClub Bank during the same
      period were funded within 24 hours after loan approval. Loan approval, and the time it takes to issue a credit
      decision, are not guaranteed and individual results vary based on credit worthiness and other factors, including
      but not limited to investor demand.
    </>
  ),
};

export const TOPUP_DISCLOSURE_5 = {
  key: 'topUp-disclosure5',
  text: (
    <>
      For TopUp Personal Loans, APR ranges from 10.49% to 35.99% and origination fee ranges from 5.00% to 8.00% of the
      additional cash out amount. APRs and origination fees are determined at the time of the application. Lowest APR is
      available to borrowers with excellent credit. Advertised rates and fees are valid as of March 21, 2024 and are
      subject to change without notice.
    </>
  ),
};

export const TOPUP_DISCLOSURE_6 = {
  key: 'topUp-disclosure6',
  text: 'Reducing debt and maintaining low credit balances may contribute to an improvement in credit score, but results are not guaranteed. Individual results vary based on multiple factors, including but not limited to payment history and credit utilization',
};

export const TOPUP_DISCLOSURE_7 = {
  key: 'topUp-disclosure7',
  text: (
    <>
      Reducing debt and maintaining low credit balances may contribute to an improvement in credit score, but results
      are not guaranteed. Individual results vary based on multiple factors, including but not limited to payment
      history and credit utilization.
    </>
  ),
};

export const TOPUP_EXAMPLE_DISCLOSURE_2 = {
  key: 'topUp-exampleDisclosure2',
  text: 'A minimum cash amount to your TopUp loan is required as shown at the time of making your credit request. The additional cash amount you receive is an estimate and subject to final adjustments upon approval.',
};
export const TOPUP_EXAMPLE_DISCLOSURE_4 = {
  key: 'topUp-exampleDisclosure4',
  text: 'A minimum cash out amount to your TopUp loan is required as shown at the time of making your credit request.',
};

export const ILOC_DISCLOSURE_1 = {
  key: 'iloc-disclosure1',
  text: '*Checking a rate through us generates a soft credit inquiry on a person’s credit report, which is visible only to that person. A hard credit inquiry, which is visible to that person and others, and which may affect that person’s credit score, only appears on the person’s credit report if and when the account is opened. Credit eligibility is not guaranteed. APR and other credit terms depend upon credit score and other key financing characteristics, including but not limited to credit usage and history.',
};

export const ILOC_DISCLOSURE_2 = {
  key: 'iloc-disclosure2',
  text: 'APR stands for Annual Percentage Rate and measures the total cost of credit annually.',
};

export const ILOC_DISCLOSURE_3 = {
  key: 'iloc-disclosure3',
  text: 'For CleanSweep line of credit, APR ranges from 8.99% to 29.99%. APRs are determined at the time of application. Lowest APR is available to borrowers with excellent credit. Advertised rates are valid as of June 30, 2023 and are subject to change without notice.',
};
export const ILOC_DISCLOSURE_4 = {
  key: 'iloc-disclosure4',
  text: 'Balance transfers may only be used to satisfy eligible debt obligations, not to purchase goods or services. Each balance transfer must be at least $200. The total of all outstanding balance transfer plans may not exceed your credit limit. We may increase or decrease the credit limit at any time and for any reason permitted by law.',
};

export const DISCLAIMERS_DATA = {
  [DISCLAIMERS.ILOC]: [ILOC_DISCLOSURE_1, ILOC_DISCLOSURE_2, ILOC_DISCLOSURE_3, ILOC_DISCLOSURE_4],
  [DISCLAIMERS.TOPUP.LANDING]: [
    TOPUP_DISCLOSURE_1,
    TOPUP_DISCLOSURE_2,
    TOPUP_DISCLOSURE_4,
    LANDING_PAGE_DISCLOSURE,
    TOPUP_DISCLOSURE_6,
  ],
  [DISCLAIMERS.TOPUP.OFFER]: [
    TOPUP_DISCLOSURE_1,
    TOPUP_DISCLOSURE_2,
    OFFER_PAGE_DISCLOSURE,
    TOPUP_DISCLOSURE_5,
    TOPUP_DISCLOSURE_7,
  ],
  [DISCLAIMERS.DEFAULT.OFFER]: [OFFER_PAGE_EXTRA_DISCLOSURE, OFFER_PAGE_DISCLOSURE],
  [DISCLAIMERS.TOPUP.EXAMPLE]: [
    TOPUP_DISCLOSURE_2,
    TOPUP_EXAMPLE_DISCLOSURE_2,
    LANDING_PAGE_DISCLOSURE,
    TOPUP_EXAMPLE_DISCLOSURE_4,
  ],
};

export const TOPUP_IDENTITY_URL = '/apply/personal/topup/identity';

export const MODAL_CHECKLIST_DATA = ['Fixed APR', 'No prepayment penalties', 'Speedy, electronic delivery of funds'];

export const INELIGIBILITY_CASES = [
  'EXCEED_MAX_OUTSTANDING_PRINCIPAL',
  'CHARGED_OFF_PRIOR_LOAN',
  'DELINQUENT_CURRENT_LOAN',
  'ENROLLED_IN_HARDSHIP_PLAN',
  'INSUFFICIENT_ON_TIME_PAYMENTS_ON_EXISTING_LOANS',
];

export const TOPUP_LEAD_TYPES = [
  TOPUP_SOURCES.PL2PL_TOPUP,
  TOPUP_SOURCES.PL_PRE_APPROVAL_TOPUP,
  TOPUP_SOURCES.PL2PL_MB_TOPUP,
  TOPUP_SOURCES.PL2PL_CP_TOPUP,
];

export const CHANNELS = {
  214642010: {
    name: 'Topup_EM',
  },
  214642011: {
    name: 'Topup_MC',
  },
  214642013: {
    name: 'Topup_Alchemy_Mobile',
  },
  214642014: {
    name: 'Topup_Alchemy_Mobile',
  },
};

export const OFFER_TYPE_NAMES = {
  control: {
    BT: 'Balance Transfer',
    CASH: 'Cash-Only',
  },
  variant: {
    BT: 'Debt Paydown',
    CASH: 'Cash',
  },
};

export const STANDALONE_DSC_TEXTS = {
  title: 'Will you use the funds from this loan to work with a debt settlement company?',
  header: 'Such as: National Debt Relief, Beyond Finance or Freedom Debt Relief',
};

export const VALID_HOME_STATUS_EXPENSE = ['RENT', 'MORTGAGE'];

export const validLivingStatus = ['RENT', 'MORTGAGE', 'OWN'];

export const VALID_LIVING_STATUS = { RENT: 'rent', MORTGAGE: 'mortgage', OWN: 'own' };

export const VARIANT_TYPES = {
  Control: 'Control',
  BT: 'BT',
  Cash: 'Cash',
  Reimagined: 'Reimagined',
};

export const BT_REIMAGINE_VALID_LOAN_PURPOSES = { cash_loan: VARIANT_TYPES.Cash, debt_consolidation: VARIANT_TYPES.BT };

export const CASH_LOAN_OPTIONS = [
  { value: 'major_purchase', label: 'Major purchase', active: true },
  { value: 'home_improvement', label: 'Home improvement', active: true },
  { value: 'life_event', label: 'Life event', active: true },
  { value: 'other', label: 'Other', active: true },
];

export const BT_REIMAGINE_LOAN_PURPOSE_OPTIONS = [
  { value: 'debt_consolidation', label: 'Debt Consolidation, Pay Off Credit Cards', active: true },
  ...CASH_LOAN_OPTIONS,
];

export default {
  OFFER_TEMPLATES,
  OFFER_MODALS,
  OFFER_TOOLTIPS,
  OFFERS,
  OFFER_UP_TAGS,
  BT_OFFER_UP_KEYS,
  CASH_OFFER_UP_KEYS,
  TIL,
  ONT_TYPES,
  KEY_PREFIX,
  CU_DISCLAIMER,
  TOPUP_DISCLOSURE_1,
  TOPUP_DISCLOSURE_2,
  OFFER_PAGE_DISCLOSURE,
  LANDING_PAGE_DISCLOSURE,
  OFFER_PAGE_EXTRA_DISCLOSURE,
  ILOC_DISCLOSURE_1,
  ILOC_DISCLOSURE_2,
  ILOC_DISCLOSURE_3,
  ILOC_DISCLOSURE_4,
  INELIGIBILITY_CASES,
};

