import React from 'react';
import PropTypes from 'prop-types';
import Section from '../components/Section';
import InfoCallout from '../components/InfoCallout';
import MemberReview from '../components/MemberReview';
import ReviewWidget from '../components/ReviewWidget';
import EasyLoanCallout from '../components/EasyLoanCallout';
import AboutLC from '../components/AboutLC';
import DMCodeInput from '../components/DMCodeInput';
import { heapTrackEvent } from '@shared_modules/baui-heap-tracking';
import memberImg from '../images/memberImg.svg';

// if you're importing other components, you'll need to put the local components (in this case Layout)
// css import at the bottom of the stack, in order to to have the css compile in the correct order.
// this import order wont be needed once we upgrade to css modules.
import './Layout.scss';

// images
import emailIcon from '../images/A/email-icon.svg';
import questionIcon from '../images/A/question-icon.svg';

import { RESOURCE_CENTER_URL, MEMBERS_REVIEW_URL, EMAIL_HELP_URL } from '../constants/userResourceUrls';

const Layout = ({ onDMCodeSubmitHandler, submitStatus }) => {
  // build out the layout for the member endorsement section
  const memberEndorsementSection = (
    <Section classes="u-bgColorSapphire100 Layout-sectionPadding">
      <div className="Layout-title u-textCenter u-title1Text u-colorDenim500 u-textAlignCenter">
        What Our Members Say
      </div>
      <div className="Layout-reviewSection">
        <ReviewWidget />
      </div>
      <div className="Layout-memberReviewSection">
        <MemberReview
          classes=""
          imgSrc={memberImg}
          quoteText="With this loan I&#39;ll be debt-free  in 2.5 years, and I&#39;ll pay less each month! It really saved me and my future."
          memberName={
            <span>
              <span className="u-colorDenim500 u-fontTextBold">Ruby,</span> a member from Virginia
              <sup>4</sup>
            </span>
          }
          moreReviewsLink={MEMBERS_REVIEW_URL}
        />
      </div>
    </Section>
  );

  // build out the layout for the info callout section
  const infoCalloutSection = (
    <Section classes="u-bgColorWhite Layout-sectionPadding">
      <InfoCallout
        classes="Layout-col1"
        linkClasses="u-textDecorationUnderline"
        imgSrc={questionIcon}
        imgAltText="question-icon"
        title={
          <span>
            <span className="Layout-textBreak Layout-textBreak--lrgInline">Questions?</span> We&#39;ve got answers
          </span>
        }
        text="Learn more about personal loans and debt management by heading to our Resource Center."
        linkText="See the Resource Center"
        linkUrl={RESOURCE_CENTER_URL}
        onClick={() => heapTrackEvent('PL DM - click resource center link')}
      />
      <InfoCallout
        classes="Layout-col2 Layout-infoMarginTop"
        linkClasses="u-textDecorationUnderline"
        imgSrc={emailIcon}
        imgAltText="email-icon"
        title="We&#39;re here to help"
        text="Reach out to our support team anytime by contacting us through our Help Center."
        linkText="Email Our Support Team"
        linkUrl={EMAIL_HELP_URL}
        onClick={() => heapTrackEvent('PL DM - click email help link')}
      />
    </Section>
  );

  return (
    <div className="Layout">
      <DMCodeInput onDMCodeSubmitHandler={onDMCodeSubmitHandler} submitStatus={submitStatus} />
      <AboutLC />
      <EasyLoanCallout
        classNames="u-bgColorSapphire100"
        contentClassNames="u-positionRelative EasyLoanCallout-content-topShift"
      />
      {memberEndorsementSection}
      {infoCalloutSection}
    </div>
  );
};

Layout.propTypes = {
  onDMCodeSubmitHandler: PropTypes.func.isRequired,
  submitStatus: PropTypes.object,
};

export default Layout;

