import React from 'react';

// We can overide the ids if they are duplicated in the page, while constructing the array.
export const areThereAnyFees = {
  id: 'areThereAnyFees',
  header: 'Are there any fees?',
  details: (
    <div>
      <p>
        <b>Origination Fee:</b> When you get a new TopUp loan, you’ll pay an origination fee of 5% to 8% of your TopUp
        cash-out amount. You will be able to review the origination fee on both your offer page and the Truth in Lending
        Disclosure before you accept. You can also visit our page on{' '}
        <a href="/personal-loan/rates-fees" target="_blank">
          How Rates & Fees Work
        </a>
        .
      </p>
      <p>
        <b>Prepayment policy:</b> You won’t be charged a penalty if you pay off your loan early.
      </p>
      <p>
        <b>Late charges:</b> LendingClub members get a 15-day grace period to make payments with no penalty. If your
        monthly loan payment is not paid within the 15-day grace period, a late fee or other penalty may apply.
      </p>
      <p>
        Please see your <b>TopUp Borrower Agreement</b> for any additional information about nonpayment, default, or
        other matters related to your loan.
      </p>
    </div>
  ),
};

export const amIRequiredToTakeOutExtraAmount = {
  id: 'amIRequiredToTakeOutExtraAmount',
  header: 'Am I required to take out extra cash?',
  details: (
    <div>
      <p>
        Yes, the TopUp<sup>TM</sup> loan requires a minimum extra cash amount for each new loan request. The minimum
        amount will vary by member and is based on the outstanding balance of your current loan. Your loan amount and
        offer page will indicate the minimum amount of funds you need to take out as part of the TopUp loan.
      </p>
      <p>At this time, LendingClub only offers personal loan refinancing with an extra cash amount.</p>
    </div>
  ),
};

export const howMuchCanIBorrow = {
  id: 'howMuchCanIBorrow',
  header: 'How much can I borrow?',
  details: (
    <div>
      <p>
        Your maximum borrowing amount is based on your existing loan’s balance and your individual credit profile. This
        could include factors like current outstanding debt, the types of debt you have, your repayment history, and
        your current income.
      </p>
      <p>
        As you apply, LendingClub will keep you informed about your minimum and maximum borrowing amounts based on the
        criteria above so you know all the requirements before accepting your loan terms.
      </p>
    </div>
  ),
};

export const whatCouldAffectTheFinalAmountOfCash = {
  id: 'whatCouldAffectTheFinalAmountOfCash',
  header: 'What could affect the final amount of cash funds I receive?',
  details: (
    <div>
      <p>
        When applying for a TopUp loan, you will get an estimate of the cash deposited to you. It’s important to note
        that there are several individual circumstances that could impact the final amount of cash you will receive.
        Please review these below:
      </p>

      <b>Example #1: How long it takes to issue your loan.</b>
      <p>
        Your payoff amount assumes 15 days of accrued interest. Depending on how long you take to accept your loan and
        complete the requirements for issuing the loan, (1) LendingClub may owe you a refund or (2) you may have a few
        extra days of accrued interest that will be deducted from your extra cash.
      </p>

      <b>Example #2: You’ve made a loan payment since accepting your offer.</b>
      <p>
        We’ll refund the payment back to you via your payment method for your existing personal loan. For example, if
        you have been paying by electronic withdrawals from your bank account, LendingClub will then electronically
        deposit any refunds owed to you.
      </p>

      <b>Example #3: You are a Florida resident</b>
      <p>
        If you are a resident of the state of Florida at the time of issuing your TopUp Loan, an additional Florida
        Stamp Tax fee will apply.
      </p>

      <b>Example #4: You have unpaid late fees or outstanding payments on your previous loan.</b>
      <p>
        If you have current payments due, it’s important to continue making payments on your existing loan until your
        new TopUp loan is issued. However, if you owe any late fees or outstanding payments on your previous loan, we’ll
        subtract that amount from your extra cash amount to cover the difference.
      </p>
      <p>
        If we are unable to disburse any loan proceeds to pay off your existing LendingClub Loan for any reason, we may
        cancel your loan.
      </p>
      <p>
        Please refer to the TopUp Borrower Agreement for additional information regarding how changes to the principal
        balance or fees incurred on your existing LendingClub loan may impact the extra cash deposited to you.
      </p>
    </div>
  ),
};

export const whatHappensAfterICheck = {
  id: 'whatHappensAfterICheck',
  header: 'What happens after I check my rate?',
  details: (
    <div>
      <b>Choose your offer</b>
      <p>Confirm the loan amount, interest rate, APR, monthly payment, and loan term if you want to move forward.</p>
      <b>Accept your loan terms</b>
      <p>
        You'll get a Truth in Lending Disclosure that outlines the total amount you're borrowing, your finance changes,
        and the origination fee. If we need any other documents or information, we'll let you know in your To-Do List.
      </p>
      <b>Get funded​</b>
      <p>
        Once approved, we’ll refinance your existing loan and send the extra cash straight to your bank account. Your
        previous loan will be paid off, and your credit bureau record will show it as successfully paid and closed in
        about 30 days.
      </p>
      <b>Maintain your credit​</b>
      <p>
        A TopUp loan could positively impact your credit down the road if you can show a history of on-time payments and
        a reduction in overall debt.
      </p>
    </div>
  ),
};

export const shouldIContinueMakingPayments = {
  id: 'shouldIContinueMakingPayments',
  header: "Should I continue making loan payments if I've been approved for a TopUp loan?",
  details: (
    <div>
      <p>
        Yes, please continue to make payments on your personal loan until your TopUp loan has been funded. We’ll send
        you a confirmation email when this occurs.
      </p>
    </div>
  ),
};

export const howCanIUseMyTopUpLoan = {
  id: 'howCanIUseMyTopUpLoan',
  header: 'How can I use my TopUp loan?',
  details: (
    <div>
      <p>
        You can use a TopUp loan for almost anything! LendingClub members use TopUp loans to pay off credit
        cards, consolidate debt, or take care of unexpected expenses. You cannot use a TopUp loan for anything
        related to education after high school, investing (such as securities or cryptocurrency), or funding illegal
        activities.
      </p>
    </div>
  ),
};

export const whatIsTopUpLoan = {
  id: 'whatIsTopUpLoan',
  header: 'What is a TopUp loan?',
  details: (
    <div>
      <p>
        The TopUp loan gives you the extra money you need and allows you to refinance your existing LendingClub Bank
        personal loan at a competitive rate.
      </p>
      <p>
        Once approved, we'll refinance your current personal loan into the TopUp loan for your remaining loan balance
        plus the extra amount you want to borrow. Then, we'll send the additional cash right to your bank account.
      </p>
      <p>
        Your new TopUp loan may have a different interest rate, term length, and monthly payment from your existing
        loan. That’s because when you refinance a personal loan and extend the time you are expected to pay it off, it
        could result in more interest paid over the loan term.
      </p>
    </div>
  ),
};

export const HowMuchCanIBorrow = {
  id: 'HowMuchCanIBorrow',
  header: 'How much can I borrow?',
  details: (
    <div>
      <p>
        You can use a TopUp loan for almost anything! LendingClub members use TopUp loans to pay off credit
        cards, consolidate debt, or take care of unexpected expenses. You can’t use a TopUp loan for anything related to
        education after high school, investing (such as securities or cryptocurrency), or funding illegal activities.
      </p>
    </div>
  ),
};

export const willCheckingMyRateAffectMyScore = {
  id: 'willCheckingMyRateAffectMyScore',
  header: 'Will checking my rate affect my credit score?',
  details: (
    <div>
      <p>
        Checking your rate with LendingClub Bank has no impact to your credit score. We’ll only do a hard credit pull
        that could impact your score if you get approved, accept your terms, and your loan is issued.
      </p>
      <p>
        Learn more about{' '}
        <a href="/resource-center/personal-finance/how-to-understand-your-credit-score" target="_blank">
          your credit score
        </a>{' '}
        and how to protect your credit health.
      </p>
    </div>
  ),
};

export const howQuicklyCanIGetTopUpLoan = {
  id: 'howQuicklyCanIGetTopUpLoan',
  header: 'How quickly can I get my TopUp loan?',
  details: (
    <div>
      <p>
        If approved, you can receive your funds in as little as 3 business days.<sup>1</sup>
      </p>
    </div>
  ),
};

export const howDebtPayoffLoanWork = {
  id: 'howDebtPayoffLoanWork',
  header: 'How does the debt paydown loan work?',
  details: (
    <div>
      <p>
        The debt paydown loan is a personal loan that helps you consolidate and pay down existing debt. Just tell us
        which accounts to pay and how much. If approved and when your loan is issued, we'll send these one-time payments
        to your creditors directly to reduce your current debt balances. After subtracting your origination fee and the
        money used to pay your creditors, any leftover cash will be deposited into your bank account. Then, you’ll pay
        down your new loan with LendingClub over time.
      </p>
    </div>
  ),
};

export const whatTypeOfDebtsQualify = {
  id: 'whatTypeOfDebtsQualify',
  header: 'What type of debts qualify for payment through LendingClub with a debt paydown loan?',
  details: (
    <div>
      <p>
        You can pay down existing credit card or personal loan debt. You can not use a debt paydown loan for student or
        business loans.
      </p>
    </div>
  ),
};

export const doINeedToPayMyDebtInFull = {
  id: 'doINeedToPayMyDebtInFull',
  header: 'Do I need to pay my debt in full or can I just pay a portion of my debt?',
  details: (
    <div>
      <p>
        You can pay a part of your debt balance with this type of loan. You can also use this loan to pay your debt in
        full, just make sure you get the exact payoff amounts from your creditors.
      </p>
    </div>
  ),
};

export const whatAreTheRequirementsToGetDiscountedRate = {
  id: 'whatAreTheRequirementsToGetDiscountedRate',
  header: 'What are the requirements to get the discounted rate?',
  details:
    "To qualify for the discounted rate, you must use at least {{btMinimumAmountValue}} of your loan amount to pay down qualifying existing debt directly through LendingClub. When you let us pay your debts directly, we're able to give you a discounted rate because we know you're using the loan to consolidate other debt.",
};

export const willIKeepDiscountedRateForLifeOfTheLoan = {
  id: 'willIKeepDiscountedRateForLifeOfTheLoan',
  header: 'Will I keep my discounted rate for the life of the loan?',
  details: (
    <div>
      <p>Yes, your discounted rate is fixed and will not change.</p>
    </div>
  ),
};

export const whenWillLendingClubProcessThePayments = {
  id: 'whenWillLendingClubProcessThePayments',
  header: 'When will LendingClub process the payments to my creditors?',
  details: (
    <div>
      <p>
        After you complete your application, we'll verify your information. If approved, your loan should be issued
        within 1 to 3 days. Immediately after that, we'll start processing the payments to your creditors. This process
        usually takes about 3 to 7 days. Please continue making at least the minimum payments on your accounts to avoid
        late fees until the payments to your creditors are confirmed.
      </p>
    </div>
  ),
};

export const howWillITrackThePayments = {
  id: 'howWillITrackThePayments',
  header: 'How will I track the payments to my creditors?',
  details: (
    <div>
      <p>
        Once your loan is issued, it usually takes about 3 to 7 days to complete the payments to your creditors. You can
        check the status of these payments by contacting your creditors directly.
      </p>
    </div>
  ),
};

export const willLendingClubCloseMyAccounts = {
  id: 'willLendingClubCloseMyAccounts',
  header:
    'Will LendingClub close my accounts with my creditors after LendingClub completes the direct payments to them?',
  details: (
    <div>
      <p>
        No, we will not close your credit card or personal loan accounts. We'll only pay your creditors the amounts you
        told us in your application, which may or may not pay your balance in full. If you want to close your accounts,
        contact your creditor or lender.
      </p>
    </div>
  ),
};

export const whenWillIGetTheRemainingFunds = {
  id: 'whenWillIGetTheRemainingFunds',
  header: 'When will I get the remaining funds deposited into my bank account?',
  details: (
    <div>
      <p>
        If approved, your loan will be issued, and we’ll subtract your origination fee and any money used to pay your
        creditors. The leftover cash will be deposited into your bank account generally within 1 to 3 days.
      </p>
    </div>
  ),
};

